<template>
  <v-card flat
          tile
          :elevation="0"
          class="pa-0">
    <v-toolbar color="cyan"
               dark
               flat>
      <v-toolbar-title>产品下载</v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-tabs v-model="tab"
                align-with-title
                show-arrows>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in items"
                 :key="item.name">
            {{ item.label }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items"
                  :key="item.name">
        <v-card flat
                tile
                :elevation="0"
                class="pa-0">
          <v-card-title>
            {{ item.label }}
          </v-card-title>
          <v-card-subtitle>
            版本:{{ item.version }}<br /> 发布时间：{{ item.date }}<br />类别：{{ item.isapp?'移动端':'PC端' }}
          </v-card-subtitle>
          <v-card-subtitle>
            <template v-for="(dlsub,index) in item.dl">
              <v-btn v-if="dlsub.url.length>0"
                     :key="index"
                     outlined
                     color="primary"
                     :href="dlsub.url"
                     rounded
                     :class="index>0?'ml-4':''">
                <v-icon>mdi-download</v-icon>
                {{ dlsub.title }}
              </v-btn>
              <v-btn v-else
                     :key="'a'+index"
                     outlined
                     color="primary"
                     @click="showAlert=true"
                     rounded
                     :class="index>0?'ml-4':''">
                <v-icon>mdi-download</v-icon>
                {{ dlsub.title }}
              </v-btn>
            </template>
            <v-snackbar v-model="showAlert"
                        :timeout="3000">
              暂不提供下载,请联系业务经理!
              <template v-slot:action="{ attrs }">
                <v-btn color="blue"
                       text
                       v-bind="attrs"
                       @click="showAlert = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-card-subtitle>
          <v-card-text v-html="item.note"></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!--脚步-->
    <v-card flat
            tile
            :elevation="0"
            class="pa-0 mx-auto"
            width="200px">
      <v-card-text>
        <a href="https://beian.miit.gov.cn/"
           target="_blank">粤ICP备2020111496号-1</a>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
const axios = require('axios')
const https = require('https');

export default {
  name: 'DownloadPage',
  components: {
  },
  mounted () {
    document.title = '产品下载'
  },
  data () {
    return {
      tab: null,
      items: [],
      showAlert: false,
    }
  },
  created () {
    this.downloadJsonFile('/', 'data.json')
  },
  methods: {
    //获取SPA中心服务器信息
    getDownloadService (baseURL) {
      // create an axios instance
      var service = axios.create({
        baseURL,
        withCredentials: false, // 跨域请求时发送 cookies
        timeout: 10 * 1000, // 超时时长
        httpsAgent: new https.Agent({
          rejectUnauthorized: false
        })
      })
      // request interceptor
      service.interceptors.request.use(
        config => {
          return config
        },
        error => {
          // Do something with request error
          return Promise.reject(error)
        }
      )

      // response interceptor
      service.interceptors.response.use(
        /**
         * If you want to get information such as headers or status
         * Please return  response => response
         */
        /**
         * 下面的注释为通过在response里，自定义code来标示请求状态
         * 当code返回如下情况则说明权限有问题，登出并返回到登录页
         * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
         * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
         */
        response => {
          if (response.status !== 200) {
            return Promise.reject('error ' + response.status)
          } else {
            return response.data
          }
        },
        error => {
          return Promise.reject(error)
        }
      )
      return service
    },

    downloadJsonFileByAxios (baseurl, filename) {
      let request = this.getDownloadService(baseurl)
      if (request === null) return Promise.reject('System unregister')
      return request({
        url: filename,
        method: 'get',
      })
    },
    downloadJsonFile (baseurl, filename) {
      let ismobile = this._isMobile()
      this.downloadJsonFileByAxios(baseurl, filename).then((response) => {
        try {
          if (ismobile) {
            //先手机端
            response.items.forEach(element => {
              if (element.isapp)
                this.items.push(element)
            });
            response.items.forEach(element => {
              if (!element.isapp)
                this.items.push(element)
            });
          } else {
            //后手机端
            response.items.forEach(element => {
              if (!element.isapp)
                this.items.push(element)
            });
            response.items.forEach(element => {
              if (element.isapp)
                this.items.push(element)
            });
          }
        } catch (error) {
          this.items.push({
            "label": "error",
            "name": "error",
            "version": "报错",
            "date": "JSON文件内容错误",
            "note": error,
            "isapp": false,
            "dl": []
          })
        }
      }).catch((err) => {
        this.items.push({
          "label": "error",
          "name": "error",
          "version": "报错",
          "date": "下载JSON文件出错",
          "note": err,
          "isapp": false,
          "dl": []
        })
      })
    },
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
}
</script>
